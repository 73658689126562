import React from 'react'
import Navbar from '../components/Navbar.tsx'
import Footer from '../components/Footer.tsx'
import Content from '../content/Content.tsx'

const MainPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}

export default MainPage