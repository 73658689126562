import React from 'react';

interface HeaderSectionProps {
  title: string;
  headerImage?: string;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ title, headerImage }) => {
  return (
    <div className="relative h-[200px] w-full">
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${headerImage || '/placeholder.svg?height=300&width=1920'})`,
          filter: 'brightness(0.7)'
        }}
      />
      <div className="absolute inset-0 bg-black/50" />
      <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
        <h1 className="text-4xl md:text-5xl font-bold text-white">{title}</h1>
      </div>
    </div>
  );
};

export default HeaderSection; 