import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const VentilationSystemsDesign = () => {
  const pageData = {
    title: "Системы вентиляции",
    headerImage: "/images/pages/projecting-images/ventilation.png?height=300&width=1920",
    description: "Мы специализируемся на проектировании эффективных систем вентиляции для различных типов зданий и помещений. Наши решения обеспечивают оптимальный воздухообмен, создавая комфортные и здоровые условия для жизни и работы.",
    services: [
      "Проектирование приточно-вытяжной вентиляции",
      "Разработка систем кондиционирования воздуха",
      "Проектирование систем воздушного отопления",
      "Разработка систем дымоудаления и противопожарной вентиляции",
      "Проектирование промышленных вентиляционных систем",
      "Расчет и подбор вентиляционного оборудования"
    ],
    processTitle: "Процесс проектирования",
    advantages: [
      "Индивидуальный подход к каждому проекту",
      "Использование современных методов расчета и проектирования",
      "Энергоэффективные и экологичные решения",
      "Соответствие всем нормативным требованиям и стандартам",
      "Оптимизация затрат на монтаж и эксплуатацию"
    ],
    process: [
      "Анализ объекта и требований заказчика",
      "Разработка концепции вентиляционной системы",
      "Создание проектной документации",
      "Расчет и подбор оборудования",
      "Согласование проекта с заказчиком",
      "Авторский надзор при реализации проекта"
    ],
    whyUs: [
      "Опытная команда инженеров-проектировщиков",
      "Использование современного программного обеспечения",
      "Комплексный подход к проектированию инженерных систем",
      "Гарантия качества и эффективности разработанных решений",
      "Поддержка на всех этапах реализации проекта"
    ]
  }

  const serviceLinks = [
    { href: '/proektirovanie/inzhenernye-sistemy', title: 'Инженерные системы' },
    { href: '/proektirovanie/sistemy-ventilyacii', title: 'Системы вентиляции' },
    { href: '/proektirovanie/elektrosnabzhenie', title: 'Электроснабжение' },
  ]

  return (
    <ServiceLayout 
      currentPath="/proektirovanie/sistemy-ventilyacii"
      title="Системы вентиляции"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}
    >
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default VentilationSystemsDesign

