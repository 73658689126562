import React from 'react'
import { Link } from 'react-router-dom'
import { RiArrowRightWideLine } from 'react-icons/ri'

const Hero = () => {
  return (
    <div className="relative h-[400px] w-full overflow-hidden">
      {/* Background Image with Overlay */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: 'url(/images/present-image.png?height=400&width=1920)',
          filter: 'grayscale(100%)'
        }}
      />
      <div className="absolute inset-0 bg-black/50" />
      
      {/* Content */}
      <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-start">
        <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 
          opacity-0 animate-[fadeIn_1s_ease-in_forwards]">
          Монтаж инженерных систем
        </h1>
        <h2 className="text-2xl md:text-3xl text-white mb-8
          opacity-0 animate-[fadeIn_1s_ease-in_0.5s_forwards]">
          в Санкт-Петербурге
        </h2>
        <Link 
          className="inline-flex items-center px-6 py-3 text-lg text-white/80 
          hover:text-white transition-colors duration-300 border-2 border-white/80 
          hover:border-white rounded-lg group
          opacity-0 animate-[fadeIn_1s_ease-in_1s_forwards]"
          to="/welcome"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('welcome')?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <span>Узнать больше</span>
          <RiArrowRightWideLine className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
        </Link>
      </div>
    </div>
  )
}

export default Hero

