import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const ElectricalSupplyDesign = () => {
  const pageData = {
    title: "Электроснабжение",
    headerImage: "/images/pages/projecting-images/electrical.png?height=300&width=1920",
    description: "Наша компания предоставляет профессиональные услуги по проектированию систем электроснабжения для объектов любой сложности. Мы разрабатываем надежные и эффективные решения, обеспечивающие бесперебойное электропитание и соответствующие всем нормативным требованиям.",
    services: [
      "Проектирование внутреннего электроснабжения зданий",
      "Разработка схем электроснабжения промышленных объектов",
      "Проектирование систем освещения",
      "Разработка систем молниезащиты и заземления",
      "Проектирование трансформаторных подстанций",
      "Расчет нагрузок и выбор оборудования"
    ],
    processTitle: "Процесс проектирования",
    advantages: [
      "Высокая квалификация инженеров-проектировщиков",
      "Использование современного программного обеспечения",
      "Энергоэффективные решения",
      "Соответствие всем нормам электробезопасности",
      "Оптимизация затрат на реализацию проекта"
    ],
    process: [
      "Сбор исходных данных и обследование объекта",
      "Разработка технического задания",
      "Создание проектной документации",
      "Расчет электрических нагрузок",
      "Выбор оборудования и материалов",
      "Согласование проекта с заказчиком и в надзорных органах"
    ],
    whyUs: [
      "Большой опыт в проектировании электроснабжения различных объектов",
      "Команда сертифицированных специалистов",
      "Использование современных технологий проектирования",
      "Гарантия качества и надежности разработанных решений",
      "Комплексный подход к проектированию инженерных систем"
    ]
  }

  const serviceLinks = [
    { href: '/proektirovanie/inzhenernye-sistemy', title: 'Инженерные системы' },
    { href: '/proektirovanie/sistemy-ventilyacii', title: 'Системы вентиляции' },
    { href: '/proektirovanie/elektrosnabzhenie', title: 'Электроснабжение' },
  ]

  return (
    <ServiceLayout 
      currentPath="/proektirovanie/elektrosnabzhenie"
      title="Электроснабжение"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}
    >
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default ElectricalSupplyDesign

