import React from 'react'
import { Mail, Phone, MapPin } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'

const services = [
  { path: "/proektirovanie/inzhenernye-sistemy", label: "Инженерные системы" },
  { path: "/montazh/vodosnabzhenie", label: "Монтаж водоснабжения и канализации" },
  { path: "/montazh/elektrika", label: "Монтаж электрооборудования" },
  { path: "/remont-kotlov", label: "Обслуживание и ремонт котлов" },
];

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-blue-600 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Свяжитесь с нами</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <Phone className="h-5 w-5 mr-2" />
                <Link to="tel:+79119266734" className="hover:underline">
                  8(911)926-67-34
                </Link>
              </li>
              <li className="flex items-center">
                <Mail className="h-5 w-5 mr-2" />
                <Link to="mailto:spb@tmontazh.ru" className="hover:underline">
                  spb@tmontazh.ru
                </Link>
              </li>
              <li className="flex items-center">
                <MapPin className="h-5 w-5 mr-2" />
                <Link 
                  to="https://yandex.ru/maps/-/CHEUzZ6p" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Ленинский пр-т, д.151, офис 713
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Наши услуги</h3>
            <ul className="space-y-2">
              {services.map((service, index) => (
                <li key={index}>
                  <Link 
                    to={service.path} 
                    className="hover:underline"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setTimeout(() => {
                        navigate(service.path);
                      }, 300);
                    }}
                  >
                    {service.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Время работы</h3>
            <p>Пн-Пт: 8:00 - 18:00</p>
            <p>Сб: 9:00 - 15:00</p>
            <p>Вс: Выходной</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-blue-500 text-center">
          <p>&copy; 2024 Tmontazh. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

