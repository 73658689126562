import React, { useEffect } from 'react'
import Hero from '../components/Hero.tsx'
import Features from '../components/Features.tsx'
import ServiceCard from '../components/ServiceCard.tsx'
import ProjectCard from '../components/ProjectCard.tsx'
import FeatureCard from '../components/FeatureCard.tsx'

const services = [
  { title: "Проектирование инженерных систем", description: "Индивидуальные инженерные решения для ваших конкретных нужд.", icon: "🏗️", href: "/proektirovanie/inzhenernye-sistemy" },
  { title: "Проектирование электроснабжения", description: "Эффективные и безопасные электросистемы для вашего объекта.", icon: "⚡", href: "/proektirovanie/elektrosnabzhenie" },
  { title: "Монтаж отопления", description: "Обеспечиваем комфорт в вашем помещении с нашими отопительными решениями.", icon: "🔥", href: "/montazh/otoplenie" },
  { title: "Монтаж водоснабжения и канализации", description: "Надежные системы водоснабжения и канализации для вашего дома или бизнеса.", icon: "💧", href: "/montazh/vodosnabzhenie" },
  { title: "Монтаж электрооборудования", description: "Профессиональные электромонтажные работы для всех ваших нужд.", icon: "🔌", href: "/montazh/elektrika" },
  { title: "Обслуживание и ремонт котлов", description: "Обеспечиваем бесперебойную работу котлов и их эффективное обслуживание.", icon: "🔧", href: "/remont-kotlov" },
]

const projects = [
  { image: "/images/offices.jpg?height=300&width=400", title: "Обустройство офисных помещений", description: "Полное проектирование и монтаж электрических и HVAS систем." },
  { image: "/images/sewerage.jpg?height=300&width=400", title: "Загородные дома", description: "Система водоснабжения и канализации для загородного дома." },
  { image: "/images/projecting-engineer-system.jpg?height=300&width=400", title: "Современные инженерные системы", description: "Проектирование индивидуальных инженерных систем для производственного предприятия." },
  { image: "/images/smart-home.jpg?height=300&width=400", title: "Интеграция в умную домашнюю систему", description: "Современная система домашней автоматизации и управления энергопотреблением." },
  { image: "/images/ventilation.jpg?height=300&width=400", title: "Вентиляционные системы", description: "Модернизация электрических и вентиляционных систем." },
  { image: "/images/boiler.jpg?height=300&width=400", title: "Установка котла", description: "Высокоэффективная котельная." },
]

const features = [
  { title: "Экспертная команда", description: "Наши инженеры имеют многолетний опыт в проектировании и реализации сложных систем." },
  { title: "Гарантия качества", description: "Мы придерживаемся самых высоких отраслевых стандартов и используем только высококачественные материалы." },
  { title: "Соблюдение сроков", description: "Мы понимаем важность сроков и всегда стараемся завершить проекты в срок." },
  { title: "Индивидуальные решения", description: "Каждый проект уникален, и мы подбираем наши услуги под ваши конкретные требования." },
  { title: "Круглосуточная поддержка", description: "Наша команда всегда готова решить любые вопросы или аварийные ситуации." },
  { title: "Конкурентные цены", description: "Мы предлагаем высококачественные услуги по разумным ценам, обеспечивая максимальную отдачу от ваших инвестиций." },
]

const HomePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
        <Hero />
        <Features/>
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
            <h1 id="welcome" className="text-4xl font-bold text-blue-600 mb-6">Добро пожаловать на наш сайт</h1>
            <p className="text-xl text-gray-700 mb-8">
                Мы предоставляем комплексные инженерные решения для ваших домашних и коммерческих нужд. От проектирования до монтажа и обслуживания, наша экспертная команда всегда готова помочь.
            </p>
            
            <section id="services" className="mb-12">
                <h2 className="text-3xl font-semibold text-blue-600 mb-6">Наши услуги</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {services.map((service, index) => (
                    <ServiceCard key={index} {...service} />
                ))}
                </div>
            </section>

            <section className="mb-12">
                <h2 className="text-3xl font-semibold text-blue-600 mb-6">Наши работы</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {projects.map((project, index) => (
                    <ProjectCard key={index} {...project} />
                ))}
                </div>
            </section>

            <section className="mb-12">
                <h2 className="text-3xl font-semibold text-blue-600 mb-6">Почему выбирают нас?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {features.map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                ))}
                </div>
            </section>
            </div>
        </main>
    </>
  )
}

export default HomePage

