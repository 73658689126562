import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const HeatingInstallation = () => {
  const pageData = {
    title: "Монтаж отопления",
    headerImage: "/images/pages/montage-images/heating-montage.png?height=300&width=1920",
    description: "Профессиональный монтаж систем отопления обеспечивает комфортную температуру в помещении и эффективное использование энергоресурсов. Мы предлагаем комплексные решения для установки и обслуживания отопительных систем любой сложности.",
    services: [
      "Проектирование системы отопления с учетом особенностей помещения и требований заказчика",
      "Монтаж радиаторов отопления и теплых полов",
      "Установка котельного оборудования и систем управления",
      "Прокладка трубопроводов и установка запорной арматуры",
      "Установка систем учета тепловой энергии",
      "Пусконаладочные работы и настройка оборудования"
    ],
    advantages: [
      "Профессиональный подход к проектированию и монтажу",
      "Использование качественных материалов и оборудования",
      "Гарантия на все виды работ",
      "Энергоэффективные решения",
      "Соблюдение всех технических норм и стандартов"
    ],
    processTitle: "Процесс монтажа",
    process: [
      "Выезд специалиста на объект для оценки и замеров",
      "Разработка проекта системы отопления",
      "Составление сметы и согласование с заказчиком",
      "Закупка необходимых материалов и оборудования",
      "Монтаж системы отопления",
      "Пусконаладочные работы",
      "Проверка работоспособности системы",
      "Сдача объекта заказчику"
    ],
    whyUs: [
      "Большой опыт в монтаже систем отопления",
      "Квалифицированные специалисты",
      "Использование современного оборудования",
      "Гарантийное обслуживание",
      "Конкурентные цены"
    ]
  }

  const serviceLinks = [
    { href: '/montazh/otoplenie', title: 'Отопление' },
    { href: '/montazh/vodosnabzhenie', title: 'Водоснабжение и канализация' },
    { href: '/montazh/elektrika', title: 'Электрика' },
    { href: '/montazh/ventilyaciya', title: 'Вентиляция' },
  ]

  return (
    <ServiceLayout 
      currentPath="/montazh/otoplenie" 
      title="Отопление"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}>
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default HeatingInstallation

