"use client"

import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ChevronDown, Phone, HousePlug } from 'lucide-react'

const DESIGN_ITEMS = [
  { path: "/proektirovanie/inzhenernye-sistemy", label: "Инженерные системы" },
  { path: "/proektirovanie/elektrosnabzhenie", label: "Электроснабжение" },
  { path: "/proektirovanie/sistemy-ventilyacii", label: "Системы вентиляции" },
] as const;

const INSTALLATION_ITEMS = [
  { path: "/montazh/otoplenie", label: "Отопление" },
  { path: "/montazh/vodosnabzhenie", label: "Водоснабжение и канализация" },
  { path: "/montazh/elektrika", label: "Электрика" },
  { path: "/montazh/ventilyaciya", label: "Вентиляция" },
] as const;

const NavItem: React.FC<{ path: string; children: React.ReactNode }> = ({ path, children }) => (
  <NavLink 
    to={path} 
    className="text-white hover:bg-blue-700 transition-all duration-300 px-3 py-2 rounded-md text-base font-medium"
  >
    {children}
  </NavLink>
)

const DropdownItem: React.FC<{ path: string; children: React.ReactNode }> = ({ path, children }) => (
  <NavLink to={path} className="block px-4 py-2 text-sm text-accent bg-white hover:bg-gray-200 transition-all duration-300">
    {children}
  </NavLink>
)

const DropdownMenu: React.FC<{ 
  title: string; 
  items: readonly { path: string; label: string }[] 
}> = ({ title, items }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  // Обработчик для проверки, находится ли курсор над кнопкой
  const handleMouseLeave = (e: React.MouseEvent) => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    if (buttonRect) {
      const isOverButton = 
        e.clientX >= buttonRect.left &&
        e.clientX <= buttonRect.right &&
        e.clientY >= buttonRect.top &&
        e.clientY <= buttonRect.bottom;
      
      if (!isOverButton) {
        setIsHovered(false);
      }
    }
  };

  return (
    <div className="relative"
      onMouseLeave={handleMouseLeave}>
      <button
        ref={buttonRef}
        onMouseEnter={() => setIsHovered(true)}
        className="text-white hover:bg-blue-700 transition-all duration-300 px-3 py-2 rounded-md text-base font-medium flex items-center">
        {title}
        <ChevronDown className={`ml-2 w-4 h-4 transition-transform duration-300 ${isHovered ? 'rotate-180' : ''}`}/>
      </button>
      <div className={`absolute left-0 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transition-all duration-300 ${
        isHovered ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}>
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          {items.map(item => (
            <DropdownItem key={item.path} path={item.path}>{item.label}</DropdownItem>
          ))}
        </div>
      </div>
    </div>
  );
};

const MobileDropdown: React.FC<{
  title: string;
  items: readonly { path: string; label: string }[];
}> = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  
  return (
    <div className="space-y-1">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center text-white px-3 py-2 text-base font-medium hover:bg-blue-700 rounded-md"
      >
        <span>{title}</span>
        <svg
          className={`w-4 h-4 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      <div 
        className={`
          transform transition-all duration-300 ease-in-out overflow-hidden
          ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}
        `}
      >
        <div className="space-y-1 pl-4">
          {items.map(item => (
            <NavLink
              key={item.path}
              to={item.path}
              className="block text-white px-2 py-2 text-base hover:bg-blue-700 rounded-md w-full"
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation();

  // English: Close menu when route changes
  // Russian: Закрываем меню при изменении маршрута
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <nav className="bg-blue-600 sticky top-0 left-0 right-0 z-50 shadow-lg transition-all duration-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <NavLink to="/" className="flex-shrink-0">
              <HousePlug className="h-6 w-6 text-white" />
            </NavLink>
          </div>
          
          <div className="hidden md:block">
            <div className="flex items-baseline space-x-4">
              <NavItem path="/">Главная</NavItem>
              <DropdownMenu title="Проектирование" items={DESIGN_ITEMS}/>
              <DropdownMenu title="Монтаж" items={INSTALLATION_ITEMS}/>
              <NavItem path="/remont-kotlov">Обслуживание и ремонт котлов</NavItem>
              <NavItem path="/kontakty">Контакты</NavItem>
            </div>
          </div>

          <div className="hidden md:block">
            <div className="flex items-center">
              <NavLink to="tel:8(911)926-67-34" className="flex items-center text-white hover:text-gray-200">
                <Phone className="h-5 w-5 mr-2" />
                <span>8 (911) 926-67-34</span>
              </NavLink>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              <div className="relative w-6 h-6">
                <svg 
                  className={`absolute block h-6 w-6 transition-all duration-300 ease-in-out ${
                    isOpen ? 'opacity-0 rotate-180 scale-0' : 'opacity-100 rotate-0 scale-100'
                  }`}
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor" 
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg 
                  className={`absolute block h-6 w-6 transition-all duration-300 ease-in-out ${
                    isOpen ? 'opacity-100 rotate-0 scale-100' : 'opacity-0 -rotate-180 scale-0'
                  }`}
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor" 
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div 
        className={`
          absolute top-16 left-0 right-0 md:hidden bg-[#3B82F6] shadow-lg z-50
          transform transition-all duration-300 ease-in-out
          ${isOpen 
            ? 'translate-y-0 opacity-100 visible' 
            : '-translate-y-full opacity-0 invisible pointer-events-none'
          }
        `}
      >
        <div className="flex flex-col px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <NavItem path="/">Главная</NavItem>
          <MobileDropdown title="Проектирование" items={DESIGN_ITEMS} />
          <MobileDropdown title="Монтаж" items={INSTALLATION_ITEMS} />
          <NavItem path="/remont-kotlov">Обслуживание и ремонт котлов</NavItem>
          <NavItem path="/kontakty">Контакты</NavItem>
        </div>
        <div className="pt-4 pb-3 border-t border-blue-700">
          <div className="flex items-center px-5">
            <a href="tel:8(911)926-67-34" className="flex items-center text-white hover:text-gray-200">
              <Phone className="h-5 w-5 mr-2" />
              <span>8 (911) 926-67-34</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
