import React from 'react'
import { Link } from 'react-router-dom'
import { clsx } from 'clsx'
import HeaderSection from './HeaderSection.tsx'

const cn = (...classes: string[]) => {
    return clsx(classes)
  }

interface ServiceLink {
  href: string
  title: string
}

interface ServiceLayoutProps {
  children: React.ReactNode
  currentPath: string
  title: string
  headerImage?: string
  serviceLinks: ServiceLink[]
}

const ServiceLayout: React.FC<ServiceLayoutProps> = ({ children, currentPath, title, headerImage, serviceLinks }) => {
  return (
    <div className="min-h-screen">
      
      {/* Hero Section with Background */}
      <HeaderSection title={title} headerImage={headerImage} />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar Navigation */}
          <div className="lg:w-1/4">
            <nav className="flex flex-col space-y-1">
              {serviceLinks.map((link) => (
                <Link
                  key={link.href}
                  to={link.href}
                  className={cn(
                    "px-4 py-3 text-sm font-medium rounded-lg transition-colors",
                    currentPath === link.href
                      ? "bg-blue-600 text-white"
                      : "bg-blue-50 text-blue-600 hover:bg-blue-100"
                  )}
                >
                  {link.title}
                </Link>
              ))}
            </nav>
          </div>

          {/* Main Content Area */}
          <div className="lg:w-3/4">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceLayout

