import React, { useEffect } from 'react'

interface ServiceContentProps {
  title: string
  description: string
  services: string[]
  processTitle: string
  advantages: string[]
  process: string[]
  whyUs: string[]
}

const ServiceContent: React.FC<ServiceContentProps> = ({
  title,
  description,
  services,
  processTitle,
  advantages,
  process,
  whyUs
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="space-y-8">
      <section>
        <h2 className="text-2xl font-bold text-gray-900 mb-4">{title}</h2>
        <p className="text-gray-700">{description}</p>
      </section>

      <section>
        <h3 className="text-xl font-bold text-gray-900 mb-4">Наши услуги</h3>
        <ul className="space-y-3">
          {services.map((service, index) => (
            <li key={index} className="flex items-start">
              <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
              <span className="text-gray-700">{service}</span>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3 className="text-xl font-bold text-gray-900 mb-4">Наши преимущества</h3>
        <ul className="space-y-3">
          {advantages.map((advantage, index) => (
            <li key={index} className="flex items-start">
              <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
              <span className="text-gray-700">{advantage}</span>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3 className="text-xl font-bold text-gray-900 mb-4">{processTitle}</h3>
        <ul className="space-y-3">
          {process.map((step, index) => (
            <li key={index} className="flex items-start">
              <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
              <span className="text-gray-700">{step}</span>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3 className="text-xl font-bold text-gray-900 mb-4">Почему выбирают нас?</h3>
        <ul className="space-y-3">
          {whyUs.map((reason, index) => (
            <li key={index} className="flex items-start">
              <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
              <span className="text-gray-700">{reason}</span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default ServiceContent 