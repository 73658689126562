import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const EngineeringSystemsDesign = () => {
  const pageData = {
    title: "Инженерные системы",
    headerImage: "/images/pages/projecting-images/engineering.png?height=300&width=1920",
    description: "Мы предоставляем комплексные услуги по проектированию инженерных систем для жилых, коммерческих и промышленных объектов. Наша команда опытных инженеров обеспечивает эффективные и инновационные решения, соответствующие всем современным стандартам и нормативам.",
    services: [
      "Проектирование систем отопления и теплоснабжения",
      "Проектирование систем водоснабжения и канализации",
      "Проектирование систем вентиляции и кондиционирования",
      "Проектирование электрических систем",
      "Разработка проектной документации",
      "Согласование проектов в надзорных органах"
    ],
    processTitle: "Процесс проектирования",
    advantages: [
      "Комплексный подход к проектированию",
      "Использование современных технологий и программного обеспечения",
      "Соответствие всем нормативным требованиям",
      "Энергоэффективные решения",
      "Оптимизация затрат на реализацию проекта"
    ],
    process: [
      "Обследование объекта и сбор исходных данных",
      "Разработка технического задания",
      "Создание концепции проекта",
      "Разработка проектной документации",
      "Согласование проекта с заказчиком",
      "Сопровождение проекта при реализации"
    ],
    whyUs: [
      "Большой опыт в проектировании различных объектов",
      "Команда квалифицированных специалистов",
      "Использование современных технологий проектирования",
      "Гарантия качества и надежности решений",
      "Индивидуальный подход к каждому проекту"
    ]
  }

  const serviceLinks = [
    { href: '/proektirovanie/inzhenernye-sistemy', title: 'Инженерные системы' },
    { href: '/proektirovanie/sistemy-ventilyacii', title: 'Системы вентиляции' },
    { href: '/proektirovanie/elektrosnabzhenie', title: 'Электроснабжение' },
  ]

  return (
    <ServiceLayout 
      currentPath="/proektirovanie/inzhenernye-sistemy"
      title="Инженерные системы"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}
    >
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default EngineeringSystemsDesign

