import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const PlumbingInstallation = () => {
  const pageData = {
    title: "Монтаж водоснабжения и канализации",
    headerImage: "/images/pages/montage-images/water-supply-montage.png?height=300&width=1920",
    description: "Качественный монтаж систем водоснабжения и канализации является основой комфорта и гигиены в любом помещении. Мы обеспечиваем профессиональную установку всех компонентов системы с учетом современных технологий и требований.",
    services: [
      "Проектирование систем водоснабжения и канализации",
      "Монтаж трубопроводов холодного и горячего водоснабжения",
      "Установка сантехнического оборудования",
      "Монтаж канализационных систем",
      "Установка систем водоочистки",
      "Монтаж насосного оборудования"
    ],
    processTitle: "Процесс монтажа",
    advantages: [
      "Комплексный подход к монтажу инженерных систем",
      "Использование качественных материалов",
      "Соблюдение строительных норм и правил",
      "Гарантия на выполненные работы",
      "Оперативное решение возникающих вопросов"
    ],
    process: [
      "Консультация и выезд на объект",
      "Разработка проекта водоснабжения и канализации",
      "Составление сметы работ",
      "Закупка необходимых материалов",
      "Монтаж систем водоснабжения и канализации",
      "Установка сантехнического оборудования",
      "Пусконаладочные работы",
      "Проверка герметичности систем"
    ],
    whyUs: [
      "Опытные специалисты в области водоснабжения",
      "Современное монтажное оборудование",
      "Гарантийное обслуживание",
      "Доступные цены на услуги",
      "Быстрые сроки выполнения работ"
    ]
  }

  const serviceLinks = [
    { href: '/montazh/otoplenie', title: 'Отопление' },
    { href: '/montazh/vodosnabzhenie', title: 'Водоснабжение и канализация' },
    { href: '/montazh/elektrika', title: 'Электрика' },
    { href: '/montazh/ventilyaciya', title: 'Вентиляция' },
  ]

  return (
    <ServiceLayout 
      currentPath="/montazh/vodosnabzhenie" 
      title="Водоснабжение и канализация"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}>
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default PlumbingInstallation

