import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const VentilationInstallation = () => {
  const pageData = {
    title: "Монтаж вентиляции",
    headerImage: "/images/pages/montage-images/ventilation-montage.png?height=300&width=1920",
    description: "Профессиональный монтаж систем вентиляции обеспечивает оптимальный микроклимат и качество воздуха в помещении. Мы предлагаем комплексные решения по установке вентиляционных систем любой сложности с учетом особенностей вашего объекта.",
    services: [
      "Проектирование систем вентиляции",
      "Монтаж приточной и вытяжной вентиляции",
      "Установка систем кондиционирования",
      "Монтаж воздуховодов и вентиляционного оборудования",
      "Установка систем рекуперации тепла",
      "Монтаж промышленных вентиляционных систем"
    ],
    processTitle: "Процесс монтажа",
    advantages: [
      "Индивидуальный подход к проектированию",
      "Использование качественного оборудования",
      "Энергоэффективные решения",
      "Соблюдение всех технических норм",
      "Гарантия на выполненные работы"
    ],
    process: [
      "Обследование объекта и консультация",
      "Разработка проекта вентиляции",
      "Подбор оборудования и материалов",
      "Составление сметы",
      "Монтаж вентиляционной системы",
      "Пусконаладочные работы",
      "Тестирование системы",
      "Сдача объекта в эксплуатацию"
    ],
    whyUs: [
      "Большой опыт в монтаже вентиляционных систем",
      "Квалифицированные специалисты",
      "Современное оборудование",
      "Гарантийное обслуживание",
      "Конкурентные цены"
    ]
  }

  const serviceLinks = [
    { href: '/montazh/otoplenie', title: 'Отопление' },
    { href: '/montazh/vodosnabzhenie', title: 'Водоснабжение и канализация' },
    { href: '/montazh/elektrika', title: 'Электрика' },
    { href: '/montazh/ventilyaciya', title: 'Вентиляция' },
  ]

  return (
    <ServiceLayout 
      currentPath="/montazh/ventilyaciya" 
      title="Вентиляция"
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}>
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default VentilationInstallation
