import React from 'react'
import { motion } from 'framer-motion';

interface FeatureCardProps {
  title: string
  description: string
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false }}
      transition={{ duration: 0.5, delay: 0.25 }}
      className="bg-white overflow-hidden shadow rounded-lg"
    >
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium text-blue-600 mb-2">{title}</h3>
        <p className="text-gray-500">{description}</p>
      </div>
    </motion.div>
  )
}

export default FeatureCard
