import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const MotionLink = motion(Link);

interface ServiceCardProps {
  title: string;
  description: string;
  icon: string;
  href: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, icon, href }) => {
  return (
    <MotionLink
      to={href}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      viewport={{ once: false }}
      transition={{ duration: 0.5 }}
      className="block bg-white overflow-hidden shadow rounded-lg hover:shadow-lg transition-shadow"
    >
      <div className="px-4 py-5 sm:p-6">
        <div className="text-4xl mb-3">{icon}</div>
        <h3 className="text-lg font-medium text-blue-600 mb-2">{title}</h3>
        <p className="text-gray-500">{description}</p>
      </div>
    </MotionLink>
  );
};

export default ServiceCard;
