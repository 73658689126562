import React from 'react'
import { Check, Award, Clock } from 'lucide-react'

const FeatureCard = ({ icon: Icon, title, description }: { 
  icon: React.ElementType, 
  title: string, 
  description: string 
}) => {
  return (
    <div className="flex flex-col items-center text-center group">
      <div className="mb-2 relative">
        <div className="relative">
          <svg width="32" height="32">
            <defs>
              <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#4F46E5" />
                <stop offset="100%" stopColor="#40E0D0" />
              </linearGradient>
            </defs>
            <Icon className="h-12 w-12" stroke="url(#icon-gradient)" />
          </svg>
        </div>
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  )
}

const Features = () => {
  const features = [
    {
      icon: Check,
      title: "Качество",
      description: "Гарантируем качество наших работ на всех этапах"
    },
    {
      icon: Award,
      title: "Профессионализм",
      description: "Команда квалифицированных инженеров"
    },
    {
      icon: Clock,
      title: "Соблюдение сроков",
      description: "Всегда выполняем работы в срок, указанный в договоре"
    }
  ];

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
        </div>
      </div>
    </div>
  )
}

export default Features

