import React from 'react'
import ServiceLayout from '../../../components/ServiceLayout.tsx'
import ServiceContent from '../../../components/ServiceContent.tsx'

const ElectricalInstallation = () => {
  const pageData = {
    title: "Монтаж электрики",
    headerImage: "/images/pages/montage-images/electrical-montage.png?height=300&width=1920",
    description: "Правильный монтаж электрических систем - это основа безопасности и комфорта в вашем доме или офисе. Качественная установка электрики обеспечивает надежное энергоснабжение, предотвращает аварийные ситуации и способствует эффективному использованию электроэнергии.",
    services: [
      "Проектирование электрических систем: Разработка индивидуального проекта электроснабжения с учетом особенностей объекта и требований клиента.",
      "Подбор оборудования и материалов: Помощь в выборе кабелей, автоматов, розеток, выключателей и других компонентов системы от ведущих производителей.",
      "Монтаж электропроводки: Установка кабелей, электрических щитов, розеток, выключателей, осветительных приборов и других элементов системы электроснабжения.",
      "Пуско-наладочные работы: Проверка и настройка системы для обеспечения ее безопасной и эффективной работы.",
      "Техническое обслуживание: Регулярное обслуживание электрических систем для предотвращения неисправностей и поддержания их надежности."
    ],
    processTitle: "Процесс монтажа",
    advantages: [
      "Профессионализм: Наши специалисты имеют многолетний опыт и высокую квалификацию в монтаже электрических систем.",
      "Индивидуальный подход: Разработка проекта и подбор оборудования с учетом особенностей вашего объекта и ваших потребностей.",
      "Качество и безопасность: Использование сертифицированных материалов и оборудования от ведущих производителей.",
      "Энергоэффективность: Установка систем, обеспечивающих максимальную эффективность и экономичность.",
      "Комплексное обслуживание: Полный цикл услуг от проектирования до технического обслуживания."
    ],
    process: [
      "Консультация и оценка объекта: Наш специалист проводит осмотр объекта и обсуждает с клиентом все требования и пожелания.",
      "Проектирование системы: Разработка детального проекта системы электроснабжения с учетом всех технических и экономических аспектов.",
      "Подбор оборудования и материалов: Рекомендации по выбору оборудования и материалов, которые наилучшим образом соответствуют потребностям клиента.",
      "Монтажные работы: Установка кабелей, электрических щитов, розеток, выключателей, осветительных приборов и других элементов системы электроснабжения.",
      "Пуско-наладочные работы: Проверка системы на работоспособность и безопасность, настройка параметров для оптимальной работы.",
      "Сдача объекта: Передача системы электроснабжения в эксплуатацию с предоставлением всех необходимых документов и инструкций.",
      "Техническое обслуживание: Регулярные проверки и обслуживание системы для поддержания ее эффективности и надежности.",
      "Заключительный отчет: Предоставление клиенту отчета о проделанных работах и рекомендаций по дальнейшей эксплуатации."
    ],
    whyUs: [
      "Опыт и надежность: Более 10 лет успешной работы в сфере монтажа электрики.",
      "Высокое качество: Использование только сертифицированных материалов и оборудования.",
      "Комплексный подход: Полный цикл услуг от проектирования до обслуживания.",
      "Индивидуальный подход: Учет всех пожеланий клиента и особенностей объекта.",
      "Конкурентные цены: Прозрачное ценообразование и доступные тарифы на услуги."
    ]
  }

  const serviceLinks = [
    { href: '/montazh/otoplenie', title: 'Отопление' },
    { href: '/montazh/vodosnabzhenie', title: 'Водоснабжение и канализация' },
    { href: '/montazh/elektrika', title: 'Электрика' },
    { href: '/montazh/ventilyaciya', title: 'Вентиляция' },
  ]

  return (
    <ServiceLayout 
      currentPath="/montazh/elektrika" 
      title="Электрика" 
      headerImage={pageData.headerImage}
      serviceLinks={serviceLinks}>
      <ServiceContent {...pageData} />
    </ServiceLayout>
  )
}

export default ElectricalInstallation
