import React from 'react'
import { Link } from 'react-router-dom'
import { RiArrowRightWideLine } from 'react-icons/ri'
import HeaderSection from './HeaderSection.tsx'
import { useEffect } from 'react'

interface ServicePageProps {
  title: string
  headerImage?: string
  content: {
    description: string
    services: string[]
    advantages: string[]
    process: string[]
    whyUs?: string[]
    certificates?: Array<{
      image: string
      alt: string
    }>
  }
}

const ServicePage: React.FC<ServicePageProps> = ({ title, headerImage, content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen">
      <HeaderSection title={title} headerImage={headerImage} />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Description */}
        <div className="mb-12">
          <p className="text-lg text-gray-700">{content.description}</p>
        </div>

        {/* Services */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Наши услуги</h2>
          <ul className="space-y-3">
            {content.services.map((service, index) => (
              <li key={index} className="flex items-start">
                <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
                <span className="text-gray-700">{service}</span>
              </li>
            ))}
          </ul>
        </section>

        {/* Advantages */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Преимущества наших услуг</h2>
          <ul className="space-y-3">
            {content.advantages.map((advantage, index) => (
              <li key={index} className="flex items-start">
                <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
                <span className="text-gray-700">{advantage}</span>
              </li>
            ))}
          </ul>
        </section>

        {/* Process */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Процесс обслуживания и ремонта</h2>
          <div className="space-y-4">
            {content.process.map((step, index) => (
              <div key={index} className="flex items-start">
                <div className="flex-shrink-0 size-8 rounded-full bg-blue-600 text-white flex items-center justify-center mr-4">
                  {index + 1}
                </div>
                <p className="text-gray-700 pt-1">{step}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Why Choose Us */}
        {content.whyUs && (
        <section className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Почему выбирают нас?</h2>
          <ul className="space-y-3">
            {content.whyUs.map((reason, index) => (
              <li key={index} className="flex items-start">
                <span className="flex-shrink-0 size-2 mt-2 rounded-full bg-blue-600 mr-3" />
                <span className="text-gray-700">{reason}</span>
              </li>
            ))}
          </ul>
        </section>
        )}

        {/* Certificates */}
        {content.certificates && (
          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Наши сертификаты</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              {content.certificates.map((cert, index) => (
                <div 
                  key={index} 
                  className="bg-white p-4 rounded-lg shadow transition-transform duration-300 hover:scale-105 cursor-pointer"
                >
                  <img
                    src={cert.image}
                    alt={cert.alt}
                    width={200}
                    height={150}
                    className="w-full h-auto object-contain"
                  />
                </div>
              ))}
            </div>
          </section>
        )}

        {/* CTA */}
        <section className="text-center">
        <Link 
          className="inline-flex items-center px-6 py-3 text-lg text-white bg-blue-600 
          hover:bg-blue-700 transition-colors duration-300 border-none 
          rounded-lg group"
          to='/kontakty'
        >
          <span>Заказать консультацию</span>
          <RiArrowRightWideLine className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
        </Link>
        </section>
      </main>
    </div>
  )
}

export default ServicePage

