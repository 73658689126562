import React from 'react'
import ServicePage from '../../components/ServicePage.tsx'

const BoilerMaintenanceAndRepair = () => {
  const pageData = {
    title: "Обслуживание и ремонт котлов",
    headerImage: "/images/pages/boiler.png?height=300&width=1920",
    content: {
      description: "Обслуживание и ремонт котлов - это ключевые услуги, которые обеспечивают надежную и безопасную работу вашей отопительной системы. Регулярное техническое обслуживание и своевременный ремонт позволяют продлить срок службы котла, снизить затраты на энергопотребление и избежать аварийных ситуаций.",
      services: [
        "Диагностика и профилактика: Тщательная проверка всех компонентов котла, выявление и устранение потенциальных проблем.",
        "Чистка и настройка: Удаление накипи и загрязнений, настройка котла для оптимальной работы",
        "Ремонт: Быстрое и качественное устранение неисправностей с использованием оригинальных запчастей.",
        "Плановое обслуживание: Регулярные проверки и обслуживание для предотвращения поломок и поддержания высокой эффективности работы котла.",
        "Консультации: Профессиональные советы по эксплуатации и обслуживанию котлов."
      ],
      advantages: [
        "Профессионализм: Наши специалисты имеют многолетний опыт работы и высокую квалификацию.",
        "Качество: Мы используем только качественные материалы и оборудование.",
        "Оперативность: Быстрое реагирование на заявки и выполнение работ в кратчайшие сроки.",
        "Гарантия: Предоставляем гарантию на все виды выполненных работ.",
        "Индивидуальный подход: Учитываем особенности каждого котла и требования клиента."
      ],
      process: [
        "Первичная консультация: Определение проблемы и консультирование по возможным решениям.",
        "Диагностика: Комплексная проверка состояния котла.",
        "Составление сметы: Определение стоимости работ и согласование с клиентом.",
        "Выполнение работ: Проведение всех необходимых работ по обслуживанию или ремонту.",
        "Проверка и настройка: Финальная проверка работоспособности котла и его настройка для оптимальной работы.",
        "Заключительный отчет: Предоставление клиенту отчета о проделанных работах и рекомендаций по дальнейшей эксплуатации."
      ],
      whyUs: [
        "Опыт и компетентность: Мы успешно работаем на рынке уже более 10 лет.",
        "Комплексный подход: Выполняем весь спектр услуг по обслуживанию и ремонту котлов.",
        "Доступные цены: Конкурентные цены при высоком качестве услуг.",
        "Клиентоориентированность: Индивидуальный подход и внимательное отношение к каждому клиенту."
      ],
      certificates: [
        {
          image: "/images/certs/cert_1.jpeg?height=150&width=200",
          alt: "Сертификат KRATS"
        },
        {
          image: "/images/certs/cert_2.jpeg?height=150&width=200",
          alt: "Сертификат Bugatti"
        },
        {
          image: "/images/certs/cert_3.jpg?height=150&width=200",
          alt: "Сертификат BAXI"
        },
        {
          image: "/images/certs/cert_4.jpg?height=150&width=200",
          alt: "Сертификат BAXI"
        }
      ]
    }
  }

  return <ServicePage {...pageData} />
}

export default BoilerMaintenanceAndRepair
