import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/Homepage.tsx';
import ContactPage from '../pages/contacts/Contactpage.tsx';
import BoilerMaintenanceAndRepair from '../pages/boiler/Boilerpage.tsx';
import HeatingInstallation from '../pages/montage/heating/HeatingInstallation.tsx';
import ElectricalInstallation from '../pages/montage/electricity/ElectricalInstallation.tsx';
import PlumbingInstallation from '../pages/montage/water-supply/PlumbingInstallation.tsx';
import VentilationInstallation from '../pages/montage/ventilation/VentilationInstallation.tsx';
import EngineeringSystemsDesign from '../pages/design/engineer-system/EngineeringSystemsDesign.tsx';
import VentilationSystemsDesign from '../pages/design/ventilation-system/VentilationSystemsDesign.tsx';
import ElectricalSupplyDesign from '../pages/design/electrical-system/ElectricalSupplyDesign.tsx';

const Content = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/proektirovanie/inzhenernye-sistemy" element={<EngineeringSystemsDesign />} />
      <Route path="/proektirovanie/sistemy-ventilyacii" element={<VentilationSystemsDesign />} />
      <Route path="/proektirovanie/elektrosnabzhenie" element={<ElectricalSupplyDesign />} />
      <Route path="/montazh/otoplenie" element={<HeatingInstallation />} />
      <Route path="/montazh/vodosnabzhenie" element={<PlumbingInstallation />} />
      <Route path="/montazh/elektrika" element={<ElectricalInstallation />} />
      <Route path="/remont-kotlov" element={<BoilerMaintenanceAndRepair />} />
      <Route path="/montazh/ventilyaciya" element={<VentilationInstallation />} />
      <Route path="/kontakty" element={<ContactPage />} />
    </Routes>
  );
};

export default Content; 