import React, { useEffect } from 'react'
import { Phone, Mail, MapPin } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import HeaderSection from '../../components/HeaderSection.tsx'

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen">
      
      {/* Hero Section with Background */}
      <HeaderSection title="Контакты" headerImage="/images/pages/contacts.png?height=300&width=1920" />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Information */}
          <div className="space-y-8">
            <h2 className="text-2xl font-bold text-gray-900">Контакты:</h2>
            
            <div className="space-y-4">
              <div className="flex items-start">
                <MapPin className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <p className="font-medium">Наш офис:</p>
                  <NavLink 
                    to="https://yandex.ru/maps/-/CHEUzZ6p" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    Ленинский пр-т 4,151 офис 713
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <Phone className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <p className="font-medium">Телефон:</p>
                  <NavLink 
                    to="tel:8(911)926-67-34" 
                    className="text-blue-600 hover:underline"
                  >
                    8 (911) 926-67-34
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <Mail className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                <div>
                  <p className="font-medium">Почта:</p>
                  <NavLink 
                    to="mailto:spb@tmontazh.ru" 
                    className="text-blue-600 hover:underline"
                  >
                    spb@tmontazh.ru
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Часы работы:</h3>
              <div className="space-y-2 text-gray-600">
                <p>Понедельник - Пятница: 9:00 - 18:00</p>
                <p>Суббота: 10:00 - 15:00</p>
                <p>Воскресенье: Выходной</p>
              </div>
            </div>
          </div>

          <div className="h-[400px] bg-gray-100 rounded-lg overflow-hidden">
            <iframe
              src="https://yandex.ru/map-widget/v1/?ll=30.300871%2C59.851457&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQxNTM0NRJV0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0JvQtdC90LjQvdGB0LrQuNC5INC_0YDQvtGB0L_QtdC60YIsIDE1MSIKDWto8kEV22dvQg%2C%2C&z=16.98"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              aria-label="Офис на карте"
              title="Расположение офиса на Яндекс Картах"
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default ContactPage

