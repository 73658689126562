import React from 'react';
import './App.css';
import MainPage from './pages/Mainpage.tsx';
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <AnimatePresence mode="wait">
      <MainPage />
    </AnimatePresence>
  );
}

export default App;
